import React from 'react';
import {Rating} from '../../../rating';

interface IReviewItemProps {
    title: string;
    date:string;
    content:string;
    rating:number
}

export const ReviewItem = (
    {title, rating, date, content}:IReviewItemProps
) => {
    return (
        <div className={'review-list-item'}>
            <div className={'review-list-item__header'}>
                <h3 className={'review-list-item__title'}>{title}</h3>
                <Rating num={rating} />
            </div>
            <span className={'review-list-item__date'}>{date.split('T')[0]}</span>
            <p className={'review-list-item__content'}>{content}</p>
        </div>
    );
};
