import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import './style.scss';

export const ContractOffer = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);


    return (
        <MainLayout title={''}>
            <div className={'rules container'}>
                <h1 className={'rules__title'}>ДОГОВОР-ОФЕРТА НА ОКАЗАНИЕ УСЛУГ ПО РАЗМЕЩЕНИЮ ИНФОРМАЦИИ
                    НА САЙТЕ «УМНЫЙ ЭКОЛОГ»
                </h1>
                <p className={'rules__text'}>Российская Федерация, г. Сочи</p>
                <p className={'rules__text'}>Общество с ограниченной ответственностью Группа Компаний «ЭКО» (ООО
                    Группа Компаний «ЭКО»), именуемый̆ в дальнейшем Исполнитель, в лице генерального директора Давыдова
                    Сергея Юрьевича, действующего на основании устава, адресует настоящий̆ Договор-оферту (далее
                    по тексту - Договор) юридическим лицам, выразившим готовность.</p>
                <p className={'rules__text'}>В соответствии с п. 2 ст. 437 Гражданского Кодекса РФ, в случае принятия
                    изложенных ниже условий и оплаты услуг, юридическое лицо, производящее акцепт этой оферты,
                    становится Заказчиком (в соответствии с п. 3 ст. 438 ГК РФ акцепт Оферты равносилен заключению
                    договора на условиях, изложенных в оферте), а Исполнитель и Заказчик совместно – Сторонами договора
                    Оферты. В связи с вышеизложенным, просим внимательно ознакомиться с текстом данного предложения
                    заключить договор (Офертой). Если Вы не согласны на ее условия и правила, Исполнитель предлагает Вам
                    отказаться от заключения договора и использования услуг Исполнителя.</p>
                <h2 className={'rules__subtitle'}>1. Определения</h2>
                <p className={'rules__text'}>1.1. Условия Договора регулируют отношения Заказчика и Исполнителя и
                    содержат следующие определения:</p>
                <p className={'rules__text'}>
                    1.1.1. <strong>Оферта</strong>
                    — настоящий документ (Договор), размещённый в сети Интернет по адресу: https://uecolog.ru. В
                    соответствии с Договором, слова оферта и Договор являются равнозначными.
                </p> <p className={'rules__text'}>
                1.1.2. <strong>Акцепт</strong>
                — полное и безоговорочное принятие оферты путем осуществления действий, указанных в п. 3.1 Договора.
            </p> <p className={'rules__text'}>
                1.1.3. <strong>Исполнитель</strong>
                — юридическое лицо (ООО Группа Компаний «ЭКО»), разместившее оферту.
            </p> <p className={'rules__text'}>
                1.1.4. <strong>Заказчик</strong>
                — юридическое лицо, заключившее Договор посредством акцепта на условиях, содержащихся в оферте.
            </p> <p className={'rules__text'}>
                1.1.5. <strong>Интернет-сайт</strong>
                — совокупность веб-страниц, размещенных на виртуальном сервере и образующих единую структуру.
            </p> <p className={'rules__text'}>
                1.1.6. <strong>Карточка компании</strong>
                — визитка компании на сайте, в которой систематизированы и отражены в удобной для пользователей форме
                данные об организации.
            </p>
                <p className={'rules__subtitle'}>2. Предмет договора</p>
                <p className={'rules__text'}>2.1. В соответствии с Договором Исполнитель обязуется оказать услуги по
                    размещению информации о компании Заказчика, включая контактные данные, на сайте Исполнителя,
                    указанном в п. 2.2 Договора (далее по тексту - Сайт), а Заказчик обязуется оплатить оказываемые
                    Исполнителем Услуги в порядке и на условиях, установленных Договором.</p>

                <p className={'rules__text'}>2.2. Исполнитель размещает информацию, предоставляемую Заказчиком, в
                    карточке компании Заказчика на сайте, размещенном в сети Интернет по адресу: http://uecolog.ru.</p>
                <p className={'rules__text'}>2.3. Объектом Договора является информационный̆ материал, размещённый̆ в
                    сети Интернет. Информационным материалом являются текстовые и графические материалы, предоставленные
                    Заказчиком. Содержание и описание информационного материала указывается в Форме для предоставления
                    информации (Приложение№ 2 к Договору), которое является неотъемлемой̆ частью Договора.</p>
                <p className={'rules__text'}>2.4. Заказчик не имеет права самостоятельно размещать информацию на Сайте.
                    Размещение информации осуществляется в следующем порядке:</p>
                <p className={'rules__text'}>2.4.1. Заказчик знакомится с договором Оферты, выбирает период размещения,
                    принимает Акцепт.</p>
                <div className={'rules__text'}>
                    2.4.2. Для формирования счета на оплату Заказчик предоставляет Исполнителю данные о компании любым
                    удобным для Заказчика способом:
                    <ul>
                        <li>Заказчик заполняет форму заявки на сайте с указанием ИНН, наименования организации,
                            электронной почты с указанием контактного лица.
                        </li>
                        <li>Заказчик отправляет данные на электронную почту Исполнителя.</li>
                        <li>Заказчик соглашается на обработку персональных данных.</li>
                    </ul>
                </div>
                <p className={'rules__text'}>2.4.3. Исполнитель формирует счет на оказание услуг по размещению и
                    высылает его на электронный адрес Заказчика. В счете Исполнитель указывает срок размещения и
                    стоимость в соответствии со Стоимостью размещения (Приложение №1 к Договору).</p>
                <p className={'rules__text'}>2.4.4. После оплаты счета Заказчиком, Исполнитель высылает на электронный
                    адрес Заказчика Форму для предоставления информации. Заказчик заполняет указанную форму не позднее
                    25го, 5го, 15го числа месяца, предшествующего периоду размещения. Если Заказчик заполняет информацию
                    для размещения позже указанного срока, услуга по размещению будет оказана Исполнителем в следующем
                    периоде размещения.</p>
                <p className={'rules__text'}>2.4.5. Перед публикацией Исполнитель проверяет правильность присланной
                    Заказчиком информации и информирует Заказчика письменно по электронной почте о том, что материалы
                    корректны и приняты к размещению.</p>
                <p className={'rules__text'}>2.4.6. На сайте информация о компании размещается три раза в месяц: к 1му,
                    10му и 20 числу месяца. Исполнитель письменно по электронной почте информирует Заказчика об оказании
                    услуги по размещению.</p>
                <p className={'rules__text'}>2.4.7. Заказчик имеет право заменить предоставленные графические материалы
                    (логотип, фотографии мест осуществления деятельности, благодарственные письма и т.д.) один раз в
                    месяц перед повторным размещением.</p>

                <p className={'rules__subtitle'}>3. Порядок заключения договора</p>
                <p className={'rules__text'}>3.1. Акцептом Договора является оплата Заказчиком выставленного
                    Исполнителем счета на оплату услуг по размещению информации о компании, согласно выбранному тарифу
                    размещения в Приложении №1.</p>
                <p className={'rules__text'}>3.2. Осуществляя акцепт Договора в порядке, определенном п. 3.1 Договора,
                    Заказчик гарантирует, что ознакомлен, соглашается, полностью и безоговорочно принимает все условия
                    Договора.</p>
                <p className={'rules__text'}>3.3. Заказчик понимает, что акцепт Договора равносилен заключению Договора
                    на условиях, изложенных в Договоре.</p>
                <p className={'rules__text'}>3.4. Оферта вступает в силу с момента подтверждения Заказчиком и действует
                    до момента отзыва и/или изменения Оферты Исполнителем.</p>

                <p className={'rules__subtitle'}>4. Права и обязанности сторон</p>
                <p className={'rules__text'}>4.1. Заказчик обязуется:</p>
                <p className={'rules__text'}>4.1.1. Своевременно передавать все необходимые документы и достоверную
                    информацию, включая информацию об организации Заказчика, в соответствии с п 2.3 настоящего
                    Договора.</p>
                <p className={'rules__text'}>4.1.2. Предоставлять полную и достоверную информацию о товарах и услугах в
                    соответствии с требованиями Закона Российской Федерации 07.02.1992 N 2300-1 (ред. «О защите прав
                    потребителей» и законодательства РФ).</p>
                <p className={'rules__text'}>4.1.3. Нести полную ответственность относительно услуг, информация о
                    которых размещается на Сайте Исполнителя, оставаясь при этом единственным лицом, ответственным за
                    недостатки таких услуг, а также за их соответствие описанию, без отсылки на ответственность третьих
                    лиц.</p>
                <p className={'rules__text'}>4.1.5. В случае отказа от согласованных Сторонами Услуг, уведомить об этом
                    Исполнителя не менее чем за 2 календарных дня до начала непосредственного оказания Услуг.</p>
                <p className={'rules__text'}>4.1.6. Своевременно и в полном объеме производить оплату Услуг в
                    соответствии с Договором.</p>

                <p className={'rules__text'}>4.2. Исполнитель обязуется:</p>
                <p className={'rules__text'}>4.2.1. Обеспечить оказание услуг по настоящему Договору.</p>
                <p className={'rules__text'}>4.2.2. Оказывать Услуги, предусмотренные Договором, с помощью третьих лиц
                    без согласования с Заказчиком.</p>
                <p className={'rules__text'}>4.2.3. Консультировать Заказчика по всем вопросам, касающимся
                    функционирования Сайта. Сложность вопроса, объем, и сроки консультирования определяется в каждом
                    конкретном случае Исполнителем самостоятельно.</p>

                <p className={'rules__text'}>4.3. Заказчик вправе:</p>
                <p className={'rules__text'}>4.3.1. Требовать от Исполнителя предоставления информации по вопросам
                    организации и обеспечения надлежащего оказания услуг.</p>
                <p className={'rules__text'}>В случае обнаружения Заказчиком ошибок при размещении информации в карточке
                    компании по настоящему Договору, произошедших по вине Исполнителя, Заказчик обязан письменно по
                    электронной почте обратиться к Исполнителю с просьбой устранить недочеты, а Исполнитель обязуется не
                    позднее трех дней исправить ошибки и сообщить в письменном виде на электронную почту о корректировке
                    Заказчику.</p>

                <p className={'rules__text'}>4.4. Исполнитель вправе:</p>
                <p className={'rules__text'}>4.4.1. Не оказывать Услуги в случае, если условия размещения информации не
                    согласованы Сторонами по вине Заказчика</p>
                <p className={'rules__text'}>4.4.2. Изменить информационный̆ материал в соответствии с внутренними
                    требованиями Исполнителя. Все изменения направлены исключительно на улучшение восприятия информации
                    потребителем и не должны искажать информацию Заказчика. При этом Заказчик, подписывая настоящий
                    Договор, подтверждает, что ознакомился на сайте, как будет выглядеть размещенная о нем информация, и
                    подтверждает, что дает безоговорочное согласие на это</p>
                <p className={'rules__text'}>4.4.3. Самостоятельно определять состав специалистов, оказывающих
                    Услуги.</p>
                <p className={'rules__text'}>4.4.4. Самостоятельно определять формы и методы оказания Услуг исходя из
                    требований законодательства, а также конкретных условий Договора.</p>
                <p className={'rules__text'}>4.4.5. Получать по письменному запросу необходимую для оказания Услуг
                    информацию от третьих лиц.</p>
                <p className={'rules__text'}>4.4.6. Редактировать или удалять с Сайта любую информацию, если такая
                    информация противоречит Договору, действующему законодательству РФ, а также в иных случаях по
                    усмотрению Исполнителя.</p>
                <p className={'rules__text'}>4.4.7. Приостановить или прекратить размещение компании Заказчика на Сайте
                    без возврата внесенной Заказчиком оплаты, если Исполнитель будет обоснованно считать, что Заказчик
                    ведет неправомерную деятельность, а также на основании сведений из ЕГРЮЛ или ЕГРИП о том, что
                    деятельность компании Заказчика прекращена или находится в стадии ликвидации или в отношении него
                    возбуждено дело о банкротстве.</p>

                <p className={'rules__subtitle'}>5. Порядок оказания услуг</p>
                <p className={'rules__text'}>5.1. Исполнитель оказывает услуги по размещению информации о компании и
                    услугах Заказчика после непосредственного обращения Заказчика к Исполнителю с заявкой на оказание
                    Услуг. Способами направления заявки являются: электронная почта info@uecolog.ru, формы на Сайте, а
                    также телефонный звонок.</p>
                <p className={'rules__text'}>5.2. Исполнитель оказывает Услуги по размещению информации в сроки,
                    установленные Договором в соответствии с Приложением №1 об оказании услуг.</p>

                <p className={'rules__subtitle'}>6. Порядок приема-передачи оказанных услуг</p>
                <p className={'rules__text'}>6.1. Моментом окончания оказания Заказчику услуг по Договору, как по
                    отдельным этапам его выполнения, так и по Договору в целом, является размещение информации о
                    компании в карточке компании на сайте. После наступления момента окончания оказания услуг по
                    Договору, как по отдельным этапам его выполнения, Заказчик не имеет право на возврат уплаченных
                    денежных средств, за исключением случаев, предусмотренных в п 10.6 настоящего Договора, услуги по
                    настоящему договору считаются оказанными Исполнителем в полном объеме и надлежащего качества.</p>
                <p className={'rules__text'}>6.2. Исполнитель формирует Акт об оказанных услугах (далее — Акт) в
                    одностороннем порядке, который приобретает силу двухстороннего в случае, предусмотренном п.6.3
                    настоящего договора, на общую сумму фактически оказанных услуг и отсылает Заказчику отсылает
                    Заказчику на email, указанный при оформлении заявки на размещение, не позднее 5 дней после
                    фактического оказания услуги размещения.</p>
                <p className={'rules__text'}>6.3. Стороны договорились, что Услуги считаются оказанными Исполнителем
                    надлежащим образом и принятыми Заказчиком в указанном в Акте объеме, если в течение 5 (пяти) рабочих
                    дня, с момента отправки Акта по электронной̆ почте, Исполнитель не получил от Заказчика
                    мотивированных письменных возражений относительно количества (объема), стоимости и качества
                    оказанных услуг или отказа от приемки Услуг.</p>
                <p className={'rules__text'}>По истечении срока, указанного выше, претензии относительно недостатков
                    Услуг, в том числе по количеству (объему), стоимости и качеству не принимаются.</p>
                <p className={'rules__text'}>6.4. Акты на бумажном носителе предоставляются Исполнителем Заказчику по
                    индивидуальному запросу Заказчика. В случае необходимости такого предоставления, Заказчик сообщает
                    об этом исполнителю в сообщении на электронную почту Исполнителя. В этом случае Заказчик обязуется
                    при получении Актов на бумажных носителях, в течение 5 (пяти) рабочих дней̆, от даты получения,
                    подписать данные Акты и один экземпляр направить на почтовый адрес Компании посредством почты РФ или
                    курьерской службой, в соответствии с реквизитами Компании, указанными в разделе 15 настоящей Оферты.
                    Приемка Клиентом данных услуг осуществляется в соответствии п. 6.3. настоящего Договора.</p>
                <p className={'rules__text'}>6.5. Стороны договорились, что Акты, отправленные на электронный адрес
                    Заказчика, имеют юридическую силу и будут применяться Заказчиком наравне с оригиналами. В случае
                    запроса Актов на бумажных носителях в порядке, предусмотренном п. 6.4, Акты, отправленные на
                    электронный адрес Заказчика, имеют юридическую силу и будут применяться Заказчиком до момента
                    получения Актов на бумажных носителях.</p>
                <p className={'rules__text'}>6.6. В случае наличия у Сторон технической возможности, по их
                    договоренности, Исполнитель имеет право направить Заказчику документы, указанные в п. 6.2.,
                    подписанными со своей стороны усиленной квалифицированной электронной подписью, посредством
                    применяемой системы электронного документооборота. Заказчик в этом случае в течение 5 (пяти)
                    рабочих дней с даты получения подписанного Исполнителем, усиленного квалифицированной электронной
                    подписью Акта, подписывает его со своей стороны усиленной квалифицированной электронной подписью
                    и направляет подписанный экземпляр Исполнителю по системе электронного документооборота.</p>
                <p className={'rules__text'}>6.7. Все споры и разногласия Стороны будут пытаться решить путем
                    переговоров. В случае невозможности разрешить споры и разногласия путем переговоров они подлежат
                    рассмотрению в Арбитражном суде.</p>

                <p className={'rules__subtitle'}>7. Стоимость услуг и порядок расчетов</p>
                <p className={'rules__text'}>7.1. По Договору взимается плата за размещение информации в соответствии с
                    Приложением №1 к договору.</p>
                <p className={'rules__text'}>7.2. Оплата по настоящему Договору осуществляется одним платежом, путем
                    100% предоплаты на основании счета, выставленного Исполнителем по электронной почте, посредством
                    перечисления денежных средств на счет Исполнителя.</p>
                <p className={'rules__text'}>7.3. Исполнитель вправе в любое время в одностороннем порядке изменить
                    стоимость Услуг. Указанные условия вступают в силу с момента их размещения, способом, установленном
                    для размещения оферты. Уведомление об изменении стоимости Услуг направляется Заказчику за 7
                    календарных дней до даты вступления в силу.</p>
                <p className={'rules__text'}>7.4. Способы оплаты по Договору:</p>
                <p className={'rules__text'}>7.4.1. Перечисление Заказчиком денежных средств в валюте Российской
                    Федерации (рубль) на расчетный счет Исполнителя. При этом обязанности Заказчика в части оплаты по
                    Договору считаются исполненными со дня зачисления денежных средств на расчетный счет
                    Исполнителя.</p>
                <p className={'rules__text'}>7.4.2. Все цены указаны без НДС (в связи с применением упрощённой системы
                    налогообложения, гл. 26.2 НК РФ).</p>

                <p className={'rules__subtitle'}>8. Персональные данные</p>
                <p className={'rules__text'}>8.1. Акцептуя условия Оферты, Заказчик дает согласие в соответствии с
                    действующим законодательством на обработку Исполнителем предоставленной им информации и (или) его
                    персональных данных (далее обработка ПД). Обработка персональных данных совершается с использованием
                    средств автоматизации или без использования таких средств с персональными данными, включая сбор,
                    запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
                    использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование,
                    удаление, уничтожение персональных данных с целью выполнения Исполнителем своих обязательств
                    принятых по условиям настоящего Договора, иных обязательств, предусмотренных Договором, в том числе
                    выполнения информационно-справочного обслуживания Заказчика, а также с целью выполнения требований
                    нормативных актов по противодействию легализации денежных средств, полученных преступным путем и
                    иных нормативных актов. Срок использования предоставленных Заказчиком персональных данных –
                    бессрочно. Заказчик также дает свое согласие на обработку и использование Исполнителем
                    предоставленной им информации и (или) его персональных данных с целью осуществления по указанному
                    Заказчиком контактному телефону и (или) контактному электронному адресу информационной рассылки (о
                    мероприятиях Исполнителя) и/или рекламной рассылки об услугах Исполнителя и/или партнера
                    Исполнителя. Согласие на получение рассылки считается предоставленным бессрочно до получения
                    Исполнителем письменного уведомления об отказе от получения рассылок или изменения выбранных опций.
                    Заказчик вправе отозвать согласие на обработку персональных данных, направив Исполнителю
                    соответствующее уведомление в случаях, предусмотренных законодательством Российской Федерации. При
                    получении указанного уведомления Исполнитель вправе приостановить оказание услуг и выполнение
                    обязанностей по Договору, требующих обработки персональных данных в ходе выполнения.</p>
                <p className={'rules__subtitle'}>9. Ответственность сторон</p>
                <p className={'rules__text'}>9.1. Стороны несут ответственность за неисполнение или ненадлежащее
                    исполнение своих обязательств по Договору в соответствии с Договором и законодательством России.</p>
                <p className={'rules__text'}>9.2. Неустойка по Договору выплачивается только на основании обоснованного
                    письменного требования Сторон.</p>
                <p className={'rules__text'}>9.3. Выплата неустойки не освобождает Стороны от выполнения обязанностей̆,
                    предусмотренных Договором.</p>
                <p className={'rules__text'}>9.4. Исполнитель не несет ответственности за недостоверную информацию о
                    компании, предоставленную Заказчиком.</p>
                <p className={'rules__text'}>9.5. Исполнитель не несет ответственности за перерывы в предоставлении
                    Услуг, вызванные техническими перебоями в работе оборудования и программного обеспечения. Вместе с
                    тем Исполнитель обязуется принимать все разумные меры для предотвращения таких перебоев. Перебои
                    подтверждаются некорректным отображением страницы сайта, а также отсутствием доступа к сайту.</p>
                <p className={'rules__text'}>9.6. Исполнитель не несет ответственности в случае ненадлежащего оказания
                    услуги, если ненадлежащее исполнение явилось следствием недостоверности, недостаточности или
                    несвоевременности предоставленных Заказчиком сведений и гарантий, а также вследствие других
                    нарушений условий настоящего Договора со стороны Заказчика.</p>
                <p className={'rules__text'}>9.7. Исполнитель не несет ответственности за несоответствие предоставленной
                    услуги ожиданиям Заказчика и/или за его субъективную оценку, такое несоответствие ожиданиям и/или
                    отрицательная субъективная оценка не являются основаниями считать услуги оказанными не качественно
                    или не в согласованном объеме.</p>
                <p className={'rules__text'}>9.8. Исполнитель не несет ответственности за невозможность обслуживания
                    Заказчика по каким-либо независящим от Исполнителя причинам. За несостоявшиеся по вине Заказчика
                    услуги, деньги, внесенные им в качестве оплаты, не возвращаются.</p>

                <p className={'rules__subtitle'}>10. Основания и порядок изменения и расторжения договора</p>
                <p className={'rules__text'}>10.1. Настоящая Оферта может быть изменена и/или отозвана в любой
                    момент. Сведения об изменении или отзыве настоящей Оферты доводятся до заказчика посредством
                    размещения (публикации) на сайте <a href={'https://uecolog.ru'} target={'_blank'}
                                                        rel="noreferrer">https://uecolog.ru</a> новой редакции настоящей
                    оферты.</p>
                <p className={'rules__text'}>10.2. В случае отзыва настоящей Оферты или внесения в нее изменений,
                    последние вступают в силу с момента размещения сведений об этом на сайте, указанном в п. 10.1.
                    Оферты, если иной срок вступления в силу не определен Офертой или дополнительно при таком сообщении.
                    При этом Заказчик обязан самостоятельно и заблаговременно знакомиться с информацией на Сайте
                    Исполнителя. Заказчик считается уведомленным обо всех условиях оказания услуги Исполнителем с
                    момента размещения информации на Сайте Исполнителя.</p>
                <p className={'rules__text'}>10.3. В случае несогласия Заказчика с такими изменениями, Заказчик обязан в
                    течение 5 (пяти) рабочих дней с даты направления Исполнителем уведомления письменно уведомить об
                    этом Исполнителя. В таком случае, Договор считается расторгнутым по истечении 30 календарных дней со
                    дня направления уведомления Исполнителем по электронной почте Заказчика. В случае неполучения
                    Исполнителем от Заказчика возражений относительно изменения условий Договора в указанный срок,
                    изменения вступают в силу с момента размещения сведений об этом на сайте, указанном в п. 10.1.</p>
                <p className={'rules__text'}>10.4. Договор может быть расторгнут по соглашению Сторон, а также в
                    одностороннем порядке по письменному требованию одной из Сторон по основаниям, предусмотренным
                    Договором и законодательством.</p>
                <p className={'rules__text'}>10.5. Расторжение Договора в одностороннем порядке производится только по
                    письменному требованию Сторон.</p>
                <p className={'rules__text'}>10.6. Если Заказчик обратился с требованием о расторжении договора в
                    одностороннем порядке позднее 3 (трех) дней с момента размещения, внесенная денежная сумма возврату
                    не подлежит, вне зависимости от выбранного периода размещения. Информация о Заказчике будет снята с
                    размещения на сайте в связи с односторонним отказом Заказчика от договора в течение 1 дня после
                    обращения.</p>
                <p className={'rules__text'}>10.7. Исполнитель вправе расторгнут договор в одностороннем внесудебном
                    порядке без возврата внесенной Заказчиком оплаты: в случае нарушения Заказчиком обязанностей,
                    предусмотренных в настоящем Договоре, нарушения порядка проведения и оплаты по настоящему договору,
                    либо общепринятых правил поведения, в том числе если Заказчик допускает использование ненормативной
                    лексики в период оказания услуги, общие призывы к недоверию Исполнителю очно и/или в сети Интернет,
                    экстремистские высказывания, оскорбление и угрозы в адрес других Заказчиков и/ или Исполнителя, иных
                    сотрудников Исполнителя и т.п. Обо всех нарушениях Исполнитель вправе сообщить через социальные сети
                    и иные средства информирования.</p>
                <p className={'rules__text'}>10.8. Исполнитель вправе в любой момент расторгнуть договор в одностороннем
                    внесудебном порядке без объяснения причин и без возмещения каких-либо убытков Заказчику. В этом
                    случае внесенная Заказчиком оплата ему возвращается за вычетом суммы, рассчитанной исходя из
                    количества дней, в течение которых было размещение информации о Заказчике на сайте (т.е.
                    Исполнителем удерживается оплата только за дни, в течение которых была оказана услуга по настоящего
                    договору).</p>
                <p className={'rules__text'}>10.9. При просрочке в предоставлении информационных материалов по вине
                    Заказчика либо просрочке, возникшей̆ из-за несоответствия материалов требованиям, указанным в
                    приложении №2, размещение на сайте переносится на следующий период размещения в текущем или
                    следующем месяце, при этом перерасчет стоимости не производится.</p>

                <p className={'rules__subtitle'}>11. Разрешение споров из договора</p>
                <p className={'rules__text'}>11.1. Стороны признают юридическую силу всех уведомлений, дополнений,
                    изменений, отчетов, актов, писем и иной корреспонденции направленных по электронной почте, указанной
                    в настоящем договоре и в заполненной Заказчиком Форме для предоставления информации. Такие документы
                    обладают полной юридической силой до момента получения сторонами оригиналов документов.</p>
                <p className={'rules__text'}>11.2. Отправка любых заявлений, уведомлений (предложений, требований)
                    осуществляется по адресам, указанным в настоящем договоре и в заполненной Заказчиком Форме для
                    предоставления информации. Недоставка соответствующих писем по причинам отсутствия адресата,
                    истечением срока хранения, отказа от получения и иным причинам, не зависящим от Исполнителя, будет
                    расцениваться как вручение соответствующих писем Заказчику по истечение 15 календарных дней с
                    момента отправки корреспонденции.</p>
                <p className={'rules__text'}>Отправка любых заявлений, уведомлений (предложений, требований), которые
                    направляются Заказчику посредством электронной почты, указанной в настоящем договоре, считаются
                    полученными Исполнителю в день их направления.</p>
                <p className={'rules__text'}>11.3. Все споры или разногласия, возникающие между Сторонами по Договору
                    или в связи с ним, разрешаются ими путем переговоров. Спор может быть передан на разрешение
                    арбитражного суда после принятия сторонами мер по досудебному урегулированию по истечении тридцати
                    календарных дней со дня направления претензии. Споры, не урегулированные путем переговоров или в
                    претензионном порядке, подлежат разрешению в соответствии с законодательством Российской Федерации в
                    Арбитражном суде по месту нахождения Исполнителя.</p>

                <p className={'rules__subtitle'}>12. Форс-мажор</p>
                <p className={'rules__text'}>12.1. Стороны освобождаются от ответственности за частичное или полное
                    неисполнение своих обязательств по договору в случае наступления и действия обстоятельств, которые
                    находятся вне контроля сторон и которые стороны не могли предвидеть или предотвратить. К таким
                    обстоятельствам по договору относятся: пожар, наводнение, землетрясение, прочие стихийные бедствия и
                    природные катастрофы, погодные условия, война или военные действия, восстание, забастовки, пандемии,
                    эпидемии, взрывы, террористический акт, изменения в законодательных актах РФ и в иных нормативно
                    правовых актах, запреты государственных органов РФ, ограничение на въезд и выезд из РФ и других
                    стран Мира и пр. (форс-мажор), но перечень не ограничивается ими.</p>
                <p className={'rules__text'}>12.2. Период освобождения от ответственности начинается с момента
                    объявления обстоятельства форс-мажором и заканчивается или закончился бы, если бы не исполняющая
                    сторона приняла меры, которые она действительно могла принять для выхода из форс-мажора. При этом
                    срок исполнения обязательств по данному Договору отодвигается соразмерно времени, в течение которого
                    действовали такие обстоятельства. Если обстоятельства непреодолимой силы будут продолжаться более
                    трех месяцев, каждая из сторон будет иметь право отказаться от дальнейшего исполнения обязательств
                    по договору, и без обращения в судебные органы, направив другой Стороне письменное извещение.</p>
                <p className={'rules__text'}>12.3. Надлежащим доказательством наличия указанных выше обстоятельств, их
                    продолжительности и последствий будут служить свидетельства и/или официальные заявления
                    соответствующих компетентных государственных органов и/или уполномоченных государственных
                    должностных лиц (служащих).</p>
                <p className={'rules__text'}>12.4. В период действия обстоятельств непреодолимой силы и их последствий
                    выполнение обязательств Сторонами по настоящему Договору приостанавливается, и санкции за их
                    неисполнение или ненадлежащее исполнение не применяются.</p>
                <p className={'rules__text'}>12.5. В случае наступления обстоятельств, указанных в разделе 12 настоящей
                    Оферты и/или иные обстоятельства, независящие от Сторон, которые повлекли невозможность исполнения
                    Договора, оплаченные Заказчиком денежные средства возврату не подлежат и сохраняются на Депозите
                    Исполнителя до момента устранения, указанных в настоящем разделе обстоятельств и появление
                    возможности исполнить Договор в полном объеме.</p>
                <p className={'rules__text'}>12.6. Стороны признают, что неплатежеспособность Сторон не является
                    форс-мажорным обстоятельством.</p>

                <p className={'rules__subtitle'}>13. Прочие условия</p>
                <p className={'rules__text'}>13.1. Стороны не имеют никаких сопутствующих устных договорённостей.
                    Содержание текста Договора полностью соответствует действительному волеизъявлению Сторон.</p>
                <p className={'rules__text'}>13.2. Вся переписка по предмету Договора, предшествующая его заключению,
                    теряет юридическую силу со дня заключения Договора.</p>
                <p className={'rules__text'}>13.3. Стороны признают, что, если какое-либо из положений Договора
                    становится недействительным в течение срока его действия вследствие изменения законодательства,
                    остальные положения Договора обязательны для Сторон в течение срока действия Договора.</p>

                <p className={'rules__subtitle'}>14. Список приложений</p>
                <p className={'rules__text'}>14.1. Приложение №1 - Стоимость размещения</p>
                <p className={'rules__text'}>14.2. Приложение №2 - Форма для предоставления информации <a
                    href={'https://forms.gle/jnopBBet7xpA8g2s5'} target={'_blank'} rel="noreferrer">(https://forms.gle/jnopBBet7xpA8g2s5
                    )</a></p>
                <p className={'rules__subtitle'}>15. Адреса и реквизиты сторон</p>
                <p className={'rules__text'}>ООО Группа Компаний «ЭКО»</p>
                <p className={'rules__text'}>105082, г. Москва, вн. тер. г. муниципальный округ Басманный, пер. Переведеновский д.13, стр.18 помещ.21Н/3</p>
                <p className={'rules__text'}>ИНН/КПП 9701190324/770101001</p>
                <p className={'rules__text'}>ОГРН 1217700575400</p>
                <p className={'rules__text'}>ФИЛИАЛ ЮЖНЫЙ ПАО БАНКА "ФК ОТКРЫТИЕ"</p>
                <p className={'rules__text'}>р/c40702810900470002449 </p>
                <p className={'rules__text'}>к/с 30101810560150000061 </p>
                <p className={'rules__text'}>БИК 046015061</p>
                <p className={'rules__text'}>Генеральный директор: Давыдов Сергей Юрьевич </p>
                <p className={'rules__text'}>E-mail: <a href={'https://info@uecolog.ru'} target={'_blank'}
                                                        rel="noreferrer">info@uecolog.ru</a></p>
                <p className={'rules__text'}>Сайт: <a href={'https://uecolog.ru'} target={'_blank'}
                                                      rel="noreferrer">https://uecolog.ru</a></p>
                <p className={'rules__text'}>Дата размещения: 21 сентября 2022 г.</p>
                <div className={'rules-box'}>
                    <p className={'rules__subtitle'}>Приложение №1</p>
                    <p className={'rules__text'}>Стоимость размещения</p>
                    <p className={'rules__text'}>Цены действительны с 21 сентября 2022 г</p>
                    <ul className={'rules-list'}>
                        <li className={'rules-list__item'}>
                            <p className={'rules-list__text'}>Период размещения</p>
                            <p className={'rules-list__text rules-list__text--price'}>Стоимость, тыс. руб</p>
                        </li>
                        <li className={'rules-list__item'}>
                            <p className={'rules-list__text'}>3 месяца размещения + 1 месяц в подарок (4 месяца)</p>
                            <p className={'rules-list__text rules-list__text--price'}>59 700</p>
                        </li>
                        <li className={'rules-list__item'}>
                            <p className={'rules-list__text'}>6 месяцев размещения + 2 в подарок (8 месяцев)</p>
                            <p className={'rules-list__text rules-list__text--price'}>119 400</p>
                        </li>
                        <li className={'rules-list__item'}>
                            <p className={'rules-list__text'}>12 месяцев размещения + 3 месяца в подарок (1 год и 3
                                месяца)</p>
                            <p className={'rules-list__text rules-list__text--price'}>238 800</p>
                        </li>
                        <li className={'rules-list__item'}>
                            <p className={'rules-list__text'}>1 месяц размещения по всей РФ по 5 кодам ФККО</p>
                            <p className={'rules-list__text rules-list__text--price'}>59 900</p>
                        </li>
                    </ul>
                </div>

            </div>
        </MainLayout>
    );
};
