import React, {useState} from 'react';
import {Button} from '../../../fields';
import {buttonTypes} from '../../../fields/types';
import {Modal} from '../../modal';
import {FeedbackForm} from '../../feedbackForm';
import {Review} from '../../review';
import {IconPen} from '../../../icons';
import {IReview} from '../types';
import {ReviewItem} from './components';


export const ReviewTab = ({reviews, rating}: { reviews: IReview[], rating: number | null }) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <div className={'tab-item'}>
            <div className={'leave-feedback'}>
                <div className={'leave-feedback__box'}>
                    <span className={'leave-feedback__count'}>{rating ?? 0}</span>
                    <div>
                        <Review isReviewTest={true} count={reviews.length} rating={rating ?? 0}/>
                        <span className={'leave-feedback__text-sm'}>Проверено <br/> Умным Экологом</span>
                    </div>
                </div>
                <div className={'leave-feedback__box'}>
                    <p className={'leave-feedback__text-form'}>Сотрудничали с этой компанией?</p>
                    <Button
                        icon={<span className={'mr-10'}><IconPen/></span>}
                        text={'Оставьте отзыв'}
                        handler={() => setShowModal(true)}
                        size={buttonTypes.xl}
                        outline={true}
                    />
                    <Modal isOpen={showModal} handleClose={() => setShowModal(false)}>
                        <FeedbackForm handleCansel={() => setShowModal(false)}/>
                    </Modal>
                </div>
            </div>
            <div className={'review-list'}>
                {reviews.map(i => (
                    <ReviewItem
                        key={i.id}
                        rating={i.rating}
                        title={i.name}
                        content={i.text}
                        date={i.created_at}/>
                ))}

            </div>
        </div>
    );
};
