import React  from 'react';
import {useAppSelector} from '../../hooks';
import {Tabs} from './tabs';
import {UtilizesCard} from './components';
import {Letters} from './letters';

export const Card = () => {
    const {currentUtilize} = useAppSelector(state => state.card);

    return currentUtilize ? (
        <>
            <UtilizesCard
                inn={currentUtilize?.inn}
                name={currentUtilize?.name}
                emails={currentUtilize?.emails}
                rating={currentUtilize?.rating}
                reviews={currentUtilize?.reviews?.length}
                is_paid={currentUtilize?.is_paid}
                is_emails_verified={currentUtilize?.is_emails_verified}
                is_phones_verified={currentUtilize?.is_phones_verified}
                is_website_url_verified={currentUtilize?.is_website_url_verified}
                website_url={currentUtilize?.website_url}
                phone_numbers={currentUtilize?.phone_numbers}
                logo={currentUtilize?.logo}
                ogrn={currentUtilize?.ogrn}
                activities={currentUtilize?.activities}
                legal_address={currentUtilize?.legal_address}
            />
            {currentUtilize?.is_paid && currentUtilize?.letters?.length > 0 ? (
                <Letters letters={currentUtilize?.letters}/>
            ) : null}
            <Tabs
                rating={currentUtilize?.rating}
                reviews={currentUtilize?.reviews}
            />
        </>
    ) : <></>;
};
